a { color: $darkest-grey; }
p {
  margin: .5rem 0 1.5rem 0;
}
.margin-0 { margin: 0; }

.opl {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  z-index: 1000;
}
.opl-feature {
  top: 0;
  left: 0;
}

.button {
  text-decoration: none;
  padding: 1rem;
  box-shadow: 4px 4px 0 0 #000;
  border: 2px solid #000;
  display: block;
  transition: all .2s;
  &:hover, &:focus {
    background: #f1f1f1;
  }
  &:active {
    box-shadow: 0 0 0 0 #000;
    transform: translate(2px, 2px);
  }
}