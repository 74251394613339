// Banner
.banner {
  background-color: $yellow;
  color: $darkest-grey;
}
.logo {
  background: url(../img/base.svg) 50% 50% no-repeat;
  width: 100px;
  height: 100px;
  display: block;
  margin: 0 auto;
  transition: all .3s;
  @include breakpoint(l) {
    margin-left: 0;
    margin-right: 0;
  }
  &:hover, &:focus {
    transition: all .3s;
    transform: scale(1.1);
  }
}
.banner-heading {
  font-size: 46px;
  line-height: 50px;
}

.button-banner {
  margin-right: 12px;
  transition: all .3s;
  text-decoration: none;
  color: $darkest-grey;
  @include background-rgba ($white, .9);
  border-color: transparent;
  display: block;
  @include breakpoint(m) {
    display: inline-block;
  }
  &:hover, &:focus {
    @include background-rgba ($white, 1);
  }
  &.button-secondary {
    margin-top: 10px;
    @include background-rgba ($white, .5);
    &:hover, &:focus {
      @include background-rgba ($white, 1);
    }
  }
}

.device {
  width: 100%;
  height: 200px;
  background: #fff;
  border-radius: 12px 12px 0 0;
  position: relative;
  padding: 40px 10px 10px 10px;
  @include breakpoint(m) {
    width: 480px;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
  }
  @include breakpoint(l) {
    width: 480px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
  &:before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #111;
    display: block;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -4px;
    border-radius: 100%;
  }
  .device-inner {
    background: url(../img/peak-crypto.svg) 50% 50% no-repeat #1f2937;
    background-size: 60%;
    width: 100%;
    height: 160px;
    margin: 0 auto;
    display: block;
    border-radius: 0;
    transition: all .3s;
    &:hover, &:focus {
      opacity: .7;
    }
    @include breakpoint(m) {
      width: 440px;
      height: 240px;
      border-radius: 0;
      background-size: 90%;
    }
    @include breakpoint(l) {
      width: 440px;
      height: 340px;
      border-radius: 10px;
    }
  }
}
