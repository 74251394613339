// Showcase
.showcase-block { background-color: $lightest-grey; }
.showcase-panel {
  background-color: $white;
  margin-bottom: 20px;
  position: relative;
  min-height: 360px;
  a {
    text-decoration: none;
    transition: all .3s;
    &:hover {
      transition: all .3s;
      color: $dark-grey;
    }
  }
}
.showcase-image {
  width: 100%;
  height: 140px;
  background-color: $darkest-grey;
  display: block;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 140px;
    position: absolute;
    top: 0;
    left: 0;
    @include background-rgba($white, 0);
    transition: all .3s;
  }
  &:hover {
    &:before {
      transition: all .3s;
      @include background-rgba($white, .2);
    }
  }
}
.showcase-sponsor {
  &:after {
    content: "Sponsored";
    font-size: 12px;
    line-height: normal;
    width: 80px;
    padding: 4px 8px;
    position: absolute;
    top: 0;
    right: 0;
    background: #ffcc00;
    color: #111;
  }
}
.showcase-peak-crypto {
  background: url(../img/peak-crypto.svg) 50% 50% no-repeat #1f2937;
  background-size: 50%;
  @include breakpoint(m) {
    background-size: 80%;
  }
}
.showcase-beauty {
  background: url(../img/beauty.jpg) 0 0 no-repeat;
  background-size: cover
}
.showcase-budherd {
  background: url(../img/budherd.jpg) 50% 50% no-repeat;
  background-size: cover
}
.showcase-tattoo {
  background: url(../img/tattoo.jpg) 0 0 no-repeat;
  background-size: cover
}
.showcase-construct {
  background: url(../img/construction.jpg) 0 0 no-repeat;
  background-size: cover
}
.showcase-tourly {
  background: url(../img/tourly.jpg) 0 0 no-repeat;
  background-size: cover
}
.showcase-event {
  background: url(../img/event.jpg) 0 0 no-repeat;
  background-size: cover
}
.showcase-business {
  background: url(../img/business.jpg) 0 0 no-repeat;
  background-size: cover
}
.showcase-restaurant {
  background: url(../img/restaurant.jpg) 0 0 no-repeat;
  background-size: cover
}
