// Global Variables
$base-font-family: "Source Sans Pro";
$base-heading-font-family: "Source Sans Pro";
$base-font-size: 18px;
$base-line-height: 26px;
$base-font-weight: 500;
$base-font-color: #232323;

// Headings
$base-h1-font-size: 32px;
$base-h1-line-height: 38px;
$base-h2-font-size: 26px;
$base-h2-line-height: 32px;
$base-h3-font-size: 22px;
$base-h3-line-height: 28px;
$base-h4-font-size: 18px;
$base-h4-line-height: 24px;
$base-h5-font-size: 16px;
$base-h5-line-height: 22px;

// Colours
$darkest-grey: #010101;
$dark-grey: #545454;
$yellow: #ffdd68;
$light-grey: #999;
$lighter-grey: #ccc;
$lightest-grey: #f9f9f9;
$white: #fff;